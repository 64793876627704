export function WEInit() {

  var obj = new Object();

  
  //---------------------------------------------
  // Automatyczna zmiany welkości fontu
  //---------------------------------------------
  obj.wcagSetFontSizeReset = function ()
  //---------------------------------------------
  {
	obj.wcagSetFontSizeHtml('100%');
	obj.wcagSetFontSize(0);
  }
  
	//---------------------------------------------
  // Automatyczna zmiany kontrastu
  //---------------------------------------------
  obj.wcagSetContrastReset = function ()
  //---------------------------------------------
  {
	obj.wcagSetContrast(0);
  }
  
  //---------------------------------------------
  // Automatyczna zmiany kontrastu
  //---------------------------------------------
  obj.wcagSetContrast = function (n)
  //---------------------------------------------
  {
    jQuery('body').removeClass('we-wcag-contrast-1 we-wcag-contrast-2 we-wcag-contrast-3');
    if (typeof (n) !== 'undefined') {
      n = parseInt(n);
      if (n) jQuery('body').addClass('we-wcag-contrast-' + n);
      jQuery.cookie("wcagSetContrast", n, { path: '/' });
    }
    else {
      var n = jQuery.cookie("wcagSetContrast");
      if (typeof (n) !== 'undefined') {
        n = parseInt(n) + 1;
        n = n > 3 ? 0 : n;
      } else n = 1;
      if (n) jQuery('body').addClass('we-wcag-contrast-' + n);
      jQuery.cookie("wcagSetContrast", n, { path: '/' });
    }
  }
  //---------------------------------------------
  // Automatyczna zmiany welkości fontu
  //---------------------------------------------
  obj.wcagSetFontSize = function (n)
  //---------------------------------------------
  {
    jQuery('body').removeClass('we-wcag-fontsize-1 we-wcag-fontsize-2 we-wcag-fontsize-3');
    if (typeof (n) !== 'undefined') {
      n = parseInt(n);
      if (n) jQuery('body').addClass('we-wcag-fontsize-' + n);
      jQuery.cookie("wcagSetFontSize", n, { path: '/' });
    }
    else {
      var n = jQuery.cookie("wcagSetFontSize");
      if (typeof (n) !== 'undefined') {
        n = parseInt(n) + 1;
        n = n > 3 ? 0 : n;
      } else n = 1;
      if (n) jQuery('body').addClass('we-wcag-fontsize-' + n);
      jQuery.cookie("wcagSetFontSize", n, { path: '/' });
    }
  }
  //---------------------------------------------
  // Automatyczna zmiany welkości fontu
  //---------------------------------------------
  obj.wcagSetFontSizeHtml = function (n)
  //---------------------------------------------
  {
    if (typeof (n) !== 'undefined') {
      if (n) {
        jQuery('html').css('font-size', n);
        jQuery.cookie("wcagSetFontSizeHtml", n, { path: '/' });
      }
    }
  }
  //---------------------------------------------
  // Dla Wysywig Editor
  //---------------------------------------------
  obj.WysiwygEditor = function (Id) {
    if ((typeof tinymce != 'undefined') && (Id != '')) {
      tinymce.init({
        selector: "textarea#" + Id,
        language: 'pl',
        plugins: ["code", "autolink", "link", "image", "lists", "advlist", "hr", "nonbreaking", "autoresize", "searchreplace", "wordcount", "visualblocks", "visualchars", "fullscreen", "insertdatetime", "paste", "table"],
        autoresize_max_height: 500,
        toolbar: "insertfile undo redo | bold italic underline strikethrough removeformat | link image | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | styleselect fontsizeselect",
        fontsize_formats: "0.5em 0.6em 0.7em 0.8em 0.9em 1.0em 1.1em 1.2em 1.3em 1.4em 1.5em 1.6em 1.7em 1.8em 1.9em 2.0em 2.1em 2.2em 2.3em 2.4em 2.5em 2.6em 2.7em 2.8em 2.9em 3.0em",
        convert_urls: false,
        relative_urls: false,
        entity_encoding: "raw"
      });
    }
  };

  //---------------------------------------------
  // Formularze
  //---------------------------------------------
  obj.Get3100FormError = function (divname, errortxt) {
    errortxt = errortxt ? errortxt : 'wystąpił błąd: #000';
    if (divname) {
      jQuery('#' + divname + 'Modal .modal-body').html(errortxt);
      jQuery('#' + divname + 'Modal').modal('show');
    }
  }

  //---------------------------------------------
  // Komunikat - czekaj
  //---------------------------------------------
  obj.Get3100FormWait = function (divname) {
    if (divname) {
      jQuery('#' + divname + 'Modal .modal-body').html('<p class="p-0 m-0">czekaj, wysyłamy dane na serwer....</p>');
      jQuery('#' + divname + 'Modal').modal('show');
    }
  }

  //---------------------------------------------
  // Dla kontynuacji danych
  //---------------------------------------------
  obj.Get3100FormNext = function (divname, txt) {
    if (divname) {
      jQuery('#' + divname + 'Modal .modal-body-message').html(txt);
      jQuery('#' + divname + 'Modal').modal('show');
    }
  }

  //---------------------------------------------
  // Ukryj okno modal
  //---------------------------------------------
  obj.Get3100FormModalHide = function (divname) {
    if (divname) {
      jQuery('#' + divname + 'Modal').modal('hide');
    }
  }

  //---------------------------------------------
  // Poprawnie zakończony formularz
  //---------------------------------------------
  obj.Get3100FormSuccess = function (divname) {
    if (divname) {
      jQuery('#' + divname + ' .we-structure-div-form').addClass('d-none');
      jQuery('#' + divname + ' .we-structure-div-info').removeClass('d-none').addClass('d-block');

      setTimeout(function () { jQuery('#' + divname + 'Modal').modal('hide'); }, 1000);
    }
  }

  //---------------------------------------------
  // Logowanie do portalu
  //---------------------------------------------
  obj.Get1190LogIn = function (DataObject) {
    var SERVICE = (typeof DataObject.SERVICE !== 'undefined') ? DataObject.SERVICE : null;
    var IDDOCMOD = (typeof DataObject.IDDOCMOD !== 'undefined') ? DataObject.IDDOCMOD : null;
    var IDDOCUMENT = (typeof DataObject.IDDOCUMENT !== 'undefined') ? DataObject.IDDOCUMENT : null;
    var IDMODULE = (typeof DataObject.IDMODULE !== 'undefined') ? DataObject.IDMODULE : null;
    var CODE = (typeof DataObject.CODE !== 'undefined') ? DataObject.CODE : null;

    if (SERVICE == null || IDDOCMOD == null || CODE == null) {
      jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Błędne wywołanie parametrów - odśwież stronę i spróbuj ponownie.</p>');
      jQuery('#Modal' + IDDOCMOD).modal('show');
    }
    else {
      var LOGIN = jQuery('#WEFORMUSERNAME' + IDDOCMOD).val();
      var PASSW = jQuery('#WEFORMPASSWORD' + IDDOCMOD).val();

      if (LOGIN != '' && PASSW != '') {
        // pobieramy TOKEN
        jQuery.ajax({
          type: "POST",
          url: SERVICE,
          data: {
            IDSERVICE: 9050,
            IDMODULE: 1151,
            IDTOOLS: 800,
            IDDOCMOD: IDDOCMOD,
            IDDOC: IDDOCUMENT,
            IDMOD: IDMODULE,
            CODE: CODE
          },

          //----------------------
          beforeSend: function () {
            //----------------------
            jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Czekaj, pobieram token...</p>');
            jQuery('#Modal' + IDDOCMOD).modal('show');
          },

          //----------------------
          success: function (data) {
            //----------------------

            if ((typeof data.STATUS !== 'undefined') || (typeof data.TOKEN !== 'undefined')) {
              var STATUS = (typeof data.STATUS !== 'undefined') ? parseInt(data.STATUS) : 0;
              var TOKEN = (typeof data.TOKEN !== 'undefined') ? data.TOKEN : '';

              if (STATUS == 1 && TOKEN.length > 0) {

                var jsSha = new jsSHA("SHA-512", "TEXT");
                jsSha.update(PASSW);
                var PASSWHASH = jsSha.getHash("HEX");

                var jsSha = new jsSHA("SHA-512", "TEXT");
                jsSha.update(PASSWHASH + TOKEN);
                var PASSWHASHTOKEN = jsSha.getHash("HEX");

                jQuery.ajax({
                  type: "POST",
                  url: SERVICE,
                  data: {
                    IDSERVICE: 1190,
                    IDMODULE: 1151,
                    IDTOOLS: 800,
                    IDRUN: 100,
                    IDDOCMOD: IDDOCMOD,
                    IDDOC: IDDOCUMENT,
                    IDMOD: IDMODULE,
                    TOKEN: TOKEN,
                    CODE: CODE,
                    LOGIN: LOGIN,
                    PASSW: PASSWHASHTOKEN
                  },
                  //----------------------
                  beforeSend: function () {
                    jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Czekaj, wysyłam dane do logowania na serwer.</p>');
                    jQuery('#Modal' + IDDOCMOD).modal('show');
                  },
                  //----------------------
                  success: function (data) {
                    if ((typeof data.STATUS !== 'undefined') || (typeof data.IDUSER !== 'undefined')) {
                      var STATUS = (typeof data.STATUS !== 'undefined') ? parseInt(data.STATUS) : 0;
                      var IDUSER = (typeof data.IDUSER !== 'undefined') ? parseInt(data.IDUSER) : 0;

                      if (STATUS == 1 && IDUSER > 0) {
                        jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Zalogowano do systemu.</p>');
                        jQuery('#Modal' + IDDOCMOD).modal('show');

                        window.location.reload(false);
                      }
                      else {
                        jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Podane dane do logowania są niepoprawne, konto nie istnieje, jest nieuwieżytelnione lub zablokowane.</p>');
                        jQuery('#Modal' + IDDOCMOD).modal('show');
                      }
                    }
                    else {
                      jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Wystąpił błąd w komunikacji z serwerem.</p>');
                      jQuery('#Modal' + IDDOCMOD).modal('show');
                    }
                  },

                  //----------------------
                  error: function (jqXHR, exception, errorThrown) {
                    var Error = obj.BSAjaxError(jqXHR, exception, errorThrown);
                    jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">' + Error + '</p>');
                    jQuery('#Modal' + IDDOCMOD).modal('show');
                  },
                  //----------------------
                  complete: function () { },
                  dataType: 'json'
                });
              }
              else {
                jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Nie udało się pobrać unikalny klucz z serwera. Proszę spróbować ponownie.</p>');
                jQuery('#Modal' + IDDOCMOD).modal('show');
              }
            }
            else {
              jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Wystąpił błąd w komunikacji z serwerem.</p>');
              jQuery('#Modal' + IDDOCMOD).modal('show');
            }
          },
          //----------------------
          error: function (jqXHR, exception, errorThrown) {
            var Error = obj.BSAjaxError(jqXHR, exception, errorThrown);
            jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">' + Error + '</p>');
            jQuery('#Modal' + IDDOCMOD).modal('show');
          },
          //----------------------
          complete: function () { },
          dataType: 'json'
        });
      }
      else {
        jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Proszę podać nazwę użytkownika oraz hasło przypisane do konta.</p>');
        jQuery('#Modal' + IDDOCMOD).modal('show');
      }
    }
  }

  //---------------------------------------------
  // Wylogowanie do portalu
  //---------------------------------------------
  obj.Get1190LogOut = function (DataObject) {
    var SERVICE = (typeof DataObject.SERVICE !== 'undefined') ? DataObject.SERVICE : null;
    var IDDOCMOD = (typeof DataObject.IDDOCMOD !== 'undefined') ? DataObject.IDDOCMOD : null;
    var IDDOCUMENT = (typeof DataObject.IDDOCUMENT !== 'undefined') ? DataObject.IDDOCUMENT : null;
    var IDMODULE = (typeof DataObject.IDMODULE !== 'undefined') ? DataObject.IDMODULE : null;
    var IDUSER = (typeof DataObject.IDUSER !== 'undefined') ? DataObject.IDUSER : null;
    var CODE = (typeof DataObject.CODE !== 'undefined') ? DataObject.CODE : null;

    if (SERVICE == null || IDDOCMOD == null || IDUSER == null || CODE == null) {
      jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Błędne wywołanie parametrów - odśwież stronę i spróbuj ponownie.</p>');
      jQuery('#Modal' + IDDOCMOD).modal('show');
    }
    else {
      jQuery.ajax({
        type: "POST",
        url: SERVICE,
        data: {
          IDSERVICE: 1190,
          IDMODULE: 1151,
          IDTOOLS: 800,
          IDRUN: 200,
          IDDOCMOD: IDDOCMOD,
          IDDOC: IDDOCUMENT,
          IDMOD: IDMODULE,
          IDUSER: IDUSER,
          CODE: CODE
        },
        //----------------------
        beforeSend: function () {
          jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Czekaj, wysyłam dane do wylogowania z serwera.</p>');
          jQuery('#Modal' + IDDOCMOD).modal('show');
        },
        //----------------------
        success: function (data) {

          if ((typeof data.STATUS !== 'undefined')) {
            var STATUS = (typeof data.STATUS !== 'undefined') ? parseInt(data.STATUS) : 0;

            if (STATUS == 1) {
              jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Wylogowano ...</p>');
              jQuery('#Modal' + IDDOCMOD).modal('show');

              window.location.reload(false);
            }
            else {
              jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Nie udało się wylogować, spróbuj ponownie.</p>');
              jQuery('#Modal' + IDDOCMOD).modal('show');
            }
          }
          else {
            jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Wystąpił błąd w komunikacji z serwerem.</p>');
            jQuery('#Modal' + IDDOCMOD).modal('show');
          }
        },
        //----------------------
        error: function (jqXHR, exception, errorThrown) {
          var Error = obj.BSAjaxError(jqXHR, exception, errorThrown);
          jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">' + Error + '</p>');
          jQuery('#Modal' + IDDOCMOD).modal('show');
        },
        //----------------------
        complete: function () { },
        dataType: 'json'
      });
    }
  }

  //---------------------------------------------
  // Rejestracja do portalu
  //---------------------------------------------
  obj.Get1190Registration = function (DataObject) {
    var SERVICE = (typeof DataObject.SERVICE !== 'undefined') ? DataObject.SERVICE : null;
    var IDDOCMOD = (typeof DataObject.IDDOCMOD !== 'undefined') ? DataObject.IDDOCMOD : null;
    var IDDOCUMENT = (typeof DataObject.IDDOCUMENT !== 'undefined') ? DataObject.IDDOCUMENT : null;
    var IDMODULE = (typeof DataObject.IDMODULE !== 'undefined') ? DataObject.IDMODULE : null;
    var CODE = (typeof DataObject.CODE !== 'undefined') ? DataObject.CODE : null;

    if (SERVICE == null || IDDOCMOD == null || CODE == null) {
      jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Błędne wywołanie parametrów - odśwież stronę i spróbuj ponownie.</p>');
      jQuery('#Modal' + IDDOCMOD).modal('show');
    }
    else {
      var USERNAME = jQuery('#WEFORMUSERNAME' + IDDOCMOD).val();
      var IMIE = jQuery('#WEFORMIMIE' + IDDOCMOD).val();
      var NAZWISKO = jQuery('#WEFORMNAZWISKO' + IDDOCMOD).val();
      var ZGODA = jQuery('#WEFORMZGODA' + IDDOCMOD).is(':checked') ? 1 : 0;

      var FieldValid = false;
      var RegObj = new RegExp(/^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/);
      FieldValid = (RegObj.test(USERNAME)) ? true : false;

      if (FieldValid && (USERNAME.length > 1) && (IMIE.length > 1) && (NAZWISKO.length > 1) && (ZGODA == 1)) {
        // pobieramy TOKEN
        jQuery.ajax({
          type: "POST",
          url: SERVICE,
          data: {
            IDSERVICE: 9050,
            IDMODULE: 1151,
            IDTOOLS: 800,
            IDDOCMOD: IDDOCMOD,
            IDDOC: IDDOCUMENT,
            IDMOD: IDMODULE,
            CODE: CODE
          },

          //----------------------
          beforeSend: function () {
            //----------------------
            jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Czekaj, pobieram token...</p>');
            jQuery('#Modal' + IDDOCMOD).modal('show');
          },

          //----------------------
          success: function (data) {
            if ((typeof data.STATUS !== 'undefined') || (typeof data.TOKEN !== 'undefined')) {
              var STATUS = (typeof data.STATUS !== 'undefined') ? parseInt(data.STATUS) : 0;
              var TOKEN = (typeof data.TOKEN !== 'undefined') ? data.TOKEN : '';

              if (STATUS == 1 && TOKEN.length > 0) {
                jQuery.ajax({
                  type: "POST",
                  url: SERVICE,
                  data: {
                    IDSERVICE: 1190,
                    IDMODULE: 1151,
                    IDTOOLS: 810,
                    IDRUN: 100,
                    IDDOCMOD: IDDOCMOD,
                    IDDOC: IDDOCUMENT,
                    IDMOD: IDMODULE,
                    TOKEN: TOKEN,
                    CODE: CODE,
                    USERNAME: USERNAME,
                    IMIE: IMIE,
                    NAZWISKO: NAZWISKO
                  },
                  //----------------------
                  beforeSend: function () {
                    jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Czekaj, wysyłam dane do logowania na serwer.</p>');
                    jQuery('#Modal' + IDDOCMOD).modal('show');
                  },
                  //----------------------
                  success: function (data) {
                    if ((typeof data.ERROR !== 'undefined') || (typeof data.IDUSER !== 'undefined')) {
                      var ERROR = (typeof data.ERROR !== 'undefined') ? parseInt(data.ERROR) : 0;
                      var IDUSER = (typeof data.IDUSER !== 'undefined') ? parseInt(data.IDUSER) : 0;

                      if (ERROR == 0 && IDUSER > 0) {
                        var ClearValue = '';

                        jQuery('#WEFORMUSERNAME' + IDDOCMOD).val(ClearValue);
                        jQuery('#WEFORMIMIE' + IDDOCMOD).val(ClearValue);
                        jQuery('#WEFORMNAZWISKO' + IDDOCMOD).val(ClearValue);
                        jQuery('#WEFORMZGODA' + IDDOCMOD).prop('checked', false);

                        jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Twoje konto zostało utworzone. Sprawdź skrzynkę pocztową podaną na etapie rejestracji (zwyczajowo poczta dochodzi do kilku minut, sprawdź czy wiadomość nie znajduje się w spamie).</p>');
                        jQuery('#Modal' + IDDOCMOD).modal('show');
                      }
                      else {
                        if (ERROR == 2) {
                          jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Nie można utworzyć konta o podanej nazwie użytkownika - konto istnieje w systemie. Użyj procedury odzyskania / przypomnienia hasła by uzyskać dostęp do swojego konta.</p>');
                          jQuery('#Modal' + IDDOCMOD).modal('show');
                        }
                        else {
                          jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Nie można utworzyć konta o podanej nazwie użytkownika - błąd komunikacyjny.</p>');
                          jQuery('#Modal' + IDDOCMOD).modal('show');
                        }
                      }
                    }
                    else {
                      jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Wystąpił błąd w komunikacji z serwerem.</p>');
                      jQuery('#Modal' + IDDOCMOD).modal('show');
                    }
                  },

                  //----------------------
                  error: function (jqXHR, exception, errorThrown) {
                    var Error = obj.BSAjaxError(jqXHR, exception, errorThrown);
                    jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">' + Error + '</p>');
                    jQuery('#Modal' + IDDOCMOD).modal('show');
                  },
                  //----------------------
                  complete: function () { },
                  dataType: 'json'
                });
              }
              else {
                jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Nie udało się pobrać unikalnego klucz z serwera. Proszę spróbować ponownie.</p>');
                jQuery('#Modal' + IDDOCMOD).modal('show');
              }
            }
            else {
              jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Wystąpił błąd w komunikacji z serwerem.</p>');
              jQuery('#Modal' + IDDOCMOD).modal('show');
            }
          },
          //----------------------
          error: function (jqXHR, exception, errorThrown) {
            var Error = obj.BSAjaxError(jqXHR, exception, errorThrown);
            jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">' + Error + '</p>');
            jQuery('#Modal' + IDDOCMOD).modal('show');
          },
          //----------------------
          complete: function () { },
          dataType: 'json'
        });
      }
      else {
        jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Proszę podać <strong>wszystkie wymagane dane</strong> oraz zaznaczyć <strong>zgody obligatoryjne</strong>. Nie można zarejestrować nowego konta w&nbsp;przypadku brakujących danych.</p>');
        jQuery('#Modal' + IDDOCMOD).modal('show');
      }
    }
  }

  //---------------------------------------------
  // Przypomnij hasło
  //---------------------------------------------
  obj.Get1190NewPasswordSendLink = function (DataObject) {
    var SERVICE = (typeof DataObject.SERVICE !== 'undefined') ? DataObject.SERVICE : null;
    var IDDOCMOD = (typeof DataObject.IDDOCMOD !== 'undefined') ? DataObject.IDDOCMOD : null;
    var IDDOCUMENT = (typeof DataObject.IDDOCUMENT !== 'undefined') ? DataObject.IDDOCUMENT : null;
    var IDMODULE = (typeof DataObject.IDMODULE !== 'undefined') ? DataObject.IDMODULE : null;
    var CODE = (typeof DataObject.CODE !== 'undefined') ? DataObject.CODE : null;

    if (SERVICE == null || IDDOCMOD == null || CODE == null) {
      jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Błędne wywołanie parametrów - odśwież stronę i spróbuj ponownie.</p>');
      jQuery('#Modal' + IDDOCMOD).modal('show');
    }
    else {
      var EMAIL = jQuery('#WEFORMEMAIL' + IDDOCMOD).val();
      var FieldValid = false;
      var RegObj = new RegExp(/^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/);
      FieldValid = (RegObj.test(EMAIL)) ? true : false;

      if (FieldValid && (EMAIL.length > 1)) {
        // pobieramy TOKEN
        jQuery.ajax({
          type: "POST",
          url: SERVICE,
          data: {
            IDSERVICE: 9050,
            IDMODULE: 1151,
            IDTOOLS: 800,
            IDDOCMOD: IDDOCMOD,
            IDDOC: IDDOCUMENT,
            IDMOD: IDMODULE,
            CODE: CODE
          },

          //----------------------
          beforeSend: function () {
            //----------------------
            jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Czekaj, pobieram token...</p>');
            jQuery('#Modal' + IDDOCMOD).modal('show');
          },

          //----------------------
          success: function (data) {
            if ((typeof data.STATUS !== 'undefined') || (typeof data.TOKEN !== 'undefined')) {
              var STATUS = (typeof data.STATUS !== 'undefined') ? parseInt(data.STATUS) : 0;
              var TOKEN = (typeof data.TOKEN !== 'undefined') ? data.TOKEN : '';

              if (STATUS == 1 && TOKEN.length > 0) {
                jQuery.ajax({
                  type: "POST",
                  url: SERVICE,
                  data: {
                    IDSERVICE: 1190,
                    IDMODULE: 1151,
                    IDTOOLS: 811,
                    IDRUN: 100,
                    IDDOCMOD: IDDOCMOD,
                    IDDOC: IDDOCUMENT,
                    IDMOD: IDMODULE,
                    TOKEN: TOKEN,
                    CODE: CODE,
                    EMAIL: EMAIL
                  },
                  //----------------------
                  beforeSend: function () {
                    jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Czekaj, wysyłam dane na serwer.</p>');
                    jQuery('#Modal' + IDDOCMOD).modal('show');
                  },
                  //----------------------
                  success: function (data) {
                    if ((typeof data.ERROR !== 'undefined') || (typeof data.IDUSER !== 'undefined')) {
                      var ERROR = (typeof data.ERROR !== 'undefined') ? parseInt(data.ERROR) : 0;
                      var IDUSER = (typeof data.IDUSER !== 'undefined') ? parseInt(data.IDUSER) : 0;

                      if (ERROR == 0 && IDUSER > 0) {
                        var ClearValue = '';

                        jQuery('#WEFORMEMAIL' + IDDOCMOD).val(ClearValue);

                        jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Wysłano link pozwalający na odzyskanie hasła. Sprawdź skrzynkę pocztową podaną na etapie rejestracji (zwyczajowo poczta dochodzi do kilku minut, sprawdź czy wiadomość nie znajduje się w spamie).</p>');
                        jQuery('#Modal' + IDDOCMOD).modal('show');
                      }
                      else {

                        if (ERROR == 4) {
                          jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Nie można wysłać linka - błędna konfiguracja modułu - brak lub usuniety template</p>');
                          jQuery('#Modal' + IDDOCMOD).modal('show');
                        }
                        else if (ERROR == 3) {
                          jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Nie można wysłać linka - błędna konfiguracja modułu - brak szablonu e-mail</p>');
                          jQuery('#Modal' + IDDOCMOD).modal('show');
                        }
                        else if (ERROR == 2) {
                          jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Nie można wysłać linka - brak adresu e-mail w koncie</p>');
                          jQuery('#Modal' + IDDOCMOD).modal('show');
                        }
                        else {
                          jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Nie można wysłać linka - nie istnieje konto z podanym adresem e-mail.</p>');
                          jQuery('#Modal' + IDDOCMOD).modal('show');
                        }
                      }
                    }
                    else {
                      jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Wystąpił błąd w komunikacji z serwerem.</p>');
                      jQuery('#Modal' + IDDOCMOD).modal('show');
                    }
                  },

                  //----------------------
                  error: function (jqXHR, exception, errorThrown) {
                    var Error = obj.BSAjaxError(jqXHR, exception, errorThrown);
                    jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">' + Error + '</p>');
                    jQuery('#Modal' + IDDOCMOD).modal('show');
                  },
                  //----------------------
                  complete: function () { },
                  dataType: 'json'
                });
              }
              else {
                jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Nie udało się pobrać unikalnego klucz z serwera. Proszę spróbować ponownie.</p>');
                jQuery('#Modal' + IDDOCMOD).modal('show');
              }
            }
            else {
              jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Wystąpił błąd w komunikacji z serwerem.</p>');
              jQuery('#Modal' + IDDOCMOD).modal('show');
            }
          },
          //----------------------
          error: function (jqXHR, exception, errorThrown) {
            var Error = obj.BSAjaxError(jqXHR, exception, errorThrown);
            jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">' + Error + '</p>');
            jQuery('#Modal' + IDDOCMOD).modal('show');
          },
          //----------------------
          complete: function () { },
          dataType: 'json'
        });
      }
      else {
        jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Proszę podać <strong>adres e-mail</strong> użyty podczas rejestracji konta.</p>');
        jQuery('#Modal' + IDDOCMOD).modal('show');
      }
    }
  }


//---------------------------------------------
  // Rejestracja do portalau - ustawienie hasała.
  //---------------------------------------------
  obj.Get1190NewPasswordSendLinkNewPassword = function (DataObject)
  //---------------------------------------------
  {
    var SERVICE = (typeof DataObject.SERVICE !== 'undefined') ? DataObject.SERVICE : null;
    var IDDOCMOD = (typeof DataObject.IDDOCMOD !== 'undefined') ? DataObject.IDDOCMOD : null;
    var IDDOCUMENT = (typeof DataObject.IDDOCUMENT !== 'undefined') ? DataObject.IDDOCUMENT : null;
    var IDMODULE = (typeof DataObject.IDMODULE !== 'undefined') ? DataObject.IDMODULE : null;
    var IDUSER = (typeof DataObject.IDUSER !== 'undefined') ? DataObject.IDUSER : null;
    var CODE = (typeof DataObject.CODE !== 'undefined') ? DataObject.CODE : null;

    if (SERVICE == null || IDDOCMOD == null || IDUSER == null || CODE == null) {
      jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Błędne wywołanie parametrów - odśwież stronę i spróbuj ponownie.</p>');
      jQuery('#Modal' + IDDOCMOD).modal('show');
    }
    else {
      var PASSWORD1 = jQuery('#WEFORMPASSWORD1' + IDDOCMOD).val();
      var PASSWORD2 = jQuery('#WEFORMPASSWORD2' + IDDOCMOD).val();
      var FieldValid = false;
      var RegObj = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W+).{8,}$/);
      FieldValid = (RegObj.test(PASSWORD1)) ? true : false;

      if (FieldValid && (PASSWORD1 == PASSWORD2) && (PASSWORD1.length > 1)) {

        var jsSha = new jsSHA("SHA-512", "TEXT");
        jsSha.update(PASSWORD1);
        var PASSWHASH = jsSha.getHash("HEX");

        jQuery.ajax({
          type: "POST",
          url: SERVICE,
          data: {
            IDSERVICE: 1190,
            IDMODULE: 1151,
            IDTOOLS: 812,
            IDRUN: 100,
            IDDOCMOD: IDDOCMOD,
            IDDOC: IDDOCUMENT,
            IDMOD: IDMODULE,
            CODE: CODE,
            IDUSER: IDUSER,
            PASSWORDNEW: PASSWHASH
          },
          //----------------------
          beforeSend: function () {
            jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Czekaj, wysyłam dane do logowania na serwer.</p>');
            jQuery('#Modal' + IDDOCMOD).modal('show');
          },
          //----------------------
          success: function (data) {
            if ((typeof data.STATUS !== 'undefined')) {
              var STATUS = (typeof data.STATUS !== 'undefined') ? parseInt(data.STATUS) : 0;

              if (STATUS == 1) {
                var ClearValue = '';
                jQuery('#WEFORMPASSWORD1' + IDDOCMOD).val(ClearValue);
                jQuery('#WEFORMPASSWORD2' + IDDOCMOD).val(ClearValue);

                jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Hasło do Twojego konto w Profilu Wnioskodawcy zostało zmienione - przejdź do logowania.</p>');
                jQuery('#Modal' + IDDOCMOD).modal('show');
              }
              else {
                jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Nie można zmienić hasła do konta. Procedura mogła zostać wczesniej zakończona. Spróbuj ponownie lub skontaktuj się z Administratorem.</p>');
                jQuery('#Modal' + IDDOCMOD).modal('show');
              }
            }
            else {
              jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Wystąpił błąd w komunikacji z serwerem.</p>');
              jQuery('#Modal' + IDDOCMOD).modal('show');
            }
          },
          //----------------------
          error: function (jqXHR, exception, errorThrown) {
            var Error = obj.BSAjaxError(jqXHR, exception, errorThrown);
            jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">' + Error + '</p>');
            jQuery('#Modal' + IDDOCMOD).modal('show');
          },
          //----------------------
          complete: function () { },
          dataType: 'json'
        });
      }
      else {
        jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Proszę wpisać hasło do konta. Identyczne hasło musi być wpisane w obu polach formularza.</p>');
        jQuery('#Modal' + IDDOCMOD).modal('show');
      }
    }
  }





  //---------------------------------------------
  // Zmiana hasła
  //---------------------------------------------
  obj.Get1190ChangePassword = function (DataObject) {
    var SERVICE = (typeof DataObject.SERVICE !== 'undefined') ? DataObject.SERVICE : null;
    var IDDOCMOD = (typeof DataObject.IDDOCMOD !== 'undefined') ? DataObject.IDDOCMOD : null;
    var IDDOCUMENT = (typeof DataObject.IDDOCUMENT !== 'undefined') ? DataObject.IDDOCUMENT : null;
    var IDMODULE = (typeof DataObject.IDMODULE !== 'undefined') ? DataObject.IDMODULE : null;
    var IDUSER = (typeof DataObject.IDUSER !== 'undefined') ? DataObject.IDUSER : null;
    var CODE = (typeof DataObject.CODE !== 'undefined') ? DataObject.CODE : null;
    var CODEUSER = (typeof DataObject.CODEUSER !== 'undefined') ? DataObject.CODEUSER : null;

    if (SERVICE == null || IDDOCMOD == null || IDUSER == null || CODE == null || CODEUSER == null) {
      jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Błędne wywołanie parametrów - odśwież stronę i spróbuj ponownie.</p>');
      jQuery('#Modal' + IDDOCMOD).modal('show');
    }
    else {
      var PASSWORD  = jQuery('#WEFORMPASSWORD'  + IDDOCMOD).val();
      var PASSWORD1 = jQuery('#WEFORMPASSWORD1' + IDDOCMOD).val();
      var PASSWORD2 = jQuery('#WEFORMPASSWORD2' + IDDOCMOD).val();

      var FieldValid = false;
      var RegObj = new RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W+).{8,}/);
      FieldValid = (RegObj.test(PASSWORD1)) ? true : false;

      if (FieldValid && (PASSWORD.length > 1) && (PASSWORD1.length > 1) && (PASSWORD2.length > 1) && (PASSWORD1 == PASSWORD2)) {
        // pobieramy TOKEN
        jQuery.ajax({
          type: "POST",
          url: SERVICE,
          data: {
            IDSERVICE: 9050,
            IDMODULE: 1151,
            IDTOOLS: 800,
            IDDOCMOD: IDDOCMOD,
            IDDOC: IDDOCUMENT,
            IDMOD: IDMODULE,
            CODE: CODE
          },

          //----------------------
          beforeSend: function () {
            //----------------------
            jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Czekaj, pobieram token...</p>');
            jQuery('#Modal' + IDDOCMOD).modal('show');
          },

          //----------------------
          success: function (data) {
            if ((typeof data.STATUS !== 'undefined') || (typeof data.TOKEN !== 'undefined')) {
              var STATUS = (typeof data.STATUS !== 'undefined') ? parseInt(data.STATUS) : 0;
              var TOKEN = (typeof data.TOKEN !== 'undefined') ? data.TOKEN : '';

              if (STATUS == 1 && TOKEN.length > 0) {
                
                var jsSha = new jsSHA("SHA-512", "TEXT");
                jsSha.update(PASSWORD);
                var PASSWORDHASH = jsSha.getHash("HEX");
                
                var jsSha = new jsSHA("SHA-512", "TEXT");
                jsSha.update(PASSWORD1);
                var PASSWORD1HASH = jsSha.getHash("HEX");
                
                jQuery.ajax({
                  type: "POST",
                  url: SERVICE,
                  data: {
                    IDSERVICE: 1190,
                    IDMODULE: 1151,
                    IDTOOLS: 821,
                    IDRUN: 100,
                    IDDOCMOD: IDDOCMOD,
                    IDDOC: IDDOCUMENT,
                    IDMOD: IDMODULE,
                    TOKEN: TOKEN,
                    CODE: CODEUSER,
                    IDUSER: IDUSER,
                    PASSWORD: PASSWORDHASH,
                    PASSWORDNEW: PASSWORD1HASH
                  },
                  //----------------------
                  beforeSend: function () {
                    jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Czekaj, wysyłam dane do zmiany hasła na serwer.</p>');
                    jQuery('#Modal' + IDDOCMOD).modal('show');
                  },
                  //----------------------
                  success: function (data) {
                    if ((typeof data.ERROR !== 'undefined') || (typeof data.IDUSER !== 'undefined')) {
                      var ERROR = (typeof data.ERROR !== 'undefined') ? parseInt(data.ERROR) : 0;
                      var IDUSER = (typeof data.IDUSER !== 'undefined') ? parseInt(data.IDUSER) : 0;

                      if (ERROR == 0 && IDUSER > 0) {
                        var ClearValue = '';
                        jQuery('#WEFORMPASSWORD' + IDDOCMOD).val(ClearValue);
                        jQuery('#WEFORMPASSWORD1' + IDDOCMOD).val(ClearValue);
                        jQuery('#WEFORMPASSWORD2' + IDDOCMOD).val(ClearValue);

                        jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Twoje hasło do konto zostało zmienione.</p>');
                        jQuery('#Modal' + IDDOCMOD).modal('show');
                      }
                      else {
                          jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Nie można zmienić hasła do Twojego konta sprawdź czy podałeś poprawne dane.</p>');
                          jQuery('#Modal' + IDDOCMOD).modal('show');
                      }
                    }
                    else {
                      jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Wystąpił błąd w komunikacji z serwerem.</p>');
                      jQuery('#Modal' + IDDOCMOD).modal('show');
                    }
                  },

                  //----------------------
                  error: function (jqXHR, exception, errorThrown) {
                    var Error = obj.BSAjaxError(jqXHR, exception, errorThrown);
                    jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">' + Error + '</p>');
                    jQuery('#Modal' + IDDOCMOD).modal('show');
                  },
                  //----------------------
                  complete: function () { },
                  dataType: 'json'
                });
              }
              else {
                jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Nie udało się pobrać unikalnego klucz z serwera. Proszę spróbować ponownie.</p>');
                jQuery('#Modal' + IDDOCMOD).modal('show');
              }
            }
            else {
              jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Wystąpił błąd w komunikacji z serwerem.</p>');
              jQuery('#Modal' + IDDOCMOD).modal('show');
            }
          },
          //----------------------
          error: function (jqXHR, exception, errorThrown) {
            var Error = obj.BSAjaxError(jqXHR, exception, errorThrown);
            jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">' + Error + '</p>');
            jQuery('#Modal' + IDDOCMOD).modal('show');
          },
          //----------------------
          complete: function () { },
          dataType: 'json'
        });
      }
      else {
        jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Proszę poprawnie wypełnić formularz. Nie można zmienić hasła do Twojego konta w przypadku gdy podane hasła są puste lub nowe hasło nie jest poprawnie powtórzone.</p>');
        jQuery('#Modal' + IDDOCMOD).modal('show');
      }
    }
  }

  //---------------------------------------------
  // Rejestracja do portalau - ustawienie hasała.
  //---------------------------------------------
  obj.Get1190RegistrationConfirmed = function (DataObject)
  //---------------------------------------------
  {
    var SERVICE = (typeof DataObject.SERVICE !== 'undefined') ? DataObject.SERVICE : null;
    var IDDOCMOD = (typeof DataObject.IDDOCMOD !== 'undefined') ? DataObject.IDDOCMOD : null;
    var IDDOCUMENT = (typeof DataObject.IDDOCUMENT !== 'undefined') ? DataObject.IDDOCUMENT : null;
    var IDMODULE = (typeof DataObject.IDMODULE !== 'undefined') ? DataObject.IDMODULE : null;
    var IDUSER = (typeof DataObject.IDUSER !== 'undefined') ? DataObject.IDUSER : null;
    var CODE = (typeof DataObject.CODE !== 'undefined') ? DataObject.CODE : null;

    if (SERVICE == null || IDDOCMOD == null || IDUSER == null || CODE == null) {
      jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Błędne wywołanie parametrów - odśwież stronę i spróbuj ponownie.</p>');
      jQuery('#Modal' + IDDOCMOD).modal('show');
    }
    else {
      var PASSWORD1 = jQuery('#WEFORMPASSWORD1' + IDDOCMOD).val();
      var PASSWORD2 = jQuery('#WEFORMPASSWORD2' + IDDOCMOD).val();
      var FieldValid = false;
      var RegObj = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W+).{8,}$/);
      FieldValid = (RegObj.test(PASSWORD1)) ? true : false;

      if (FieldValid && (PASSWORD1 == PASSWORD2) && (PASSWORD1.length > 1)) {

        var jsSha = new jsSHA("SHA-512", "TEXT");
        jsSha.update(PASSWORD1);
        var PASSWHASH = jsSha.getHash("HEX");

        jQuery.ajax({
          type: "POST",
          url: SERVICE,
          data: {
            IDSERVICE: 1190,
            IDMODULE: 1151,
            IDTOOLS: 820,
            IDRUN: 100,
            IDDOCMOD: IDDOCMOD,
            IDDOC: IDDOCUMENT,
            IDMOD: IDMODULE,
            CODE: CODE,
            IDUSER: IDUSER,
            PASSWORDNEW: PASSWHASH
          },
          //----------------------
          beforeSend: function () {
            jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Czekaj, wysyłam dane do logowania na serwer.</p>');
            jQuery('#Modal' + IDDOCMOD).modal('show');
          },
          //----------------------
          success: function (data) {
            if ((typeof data.STATUS !== 'undefined')) {
              var STATUS = (typeof data.STATUS !== 'undefined') ? parseInt(data.STATUS) : 0;

              if (STATUS == 1) {
                var ClearValue = '';
                jQuery('#WEFORMPASSWORD1' + IDDOCMOD).val(ClearValue);
                jQuery('#WEFORMPASSWORD2' + IDDOCMOD).val(ClearValue);

                jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Konto zostało utworzone - przejdź do logowania.</p>');
                jQuery('#Modal' + IDDOCMOD).modal('show');
              }
              else if (STATUS == 2) {
                var ClearValue = '';

                jQuery('#WEFORMPASSWORD1' + IDDOCMOD).val(ClearValue);
                jQuery('#WEFORMPASSWORD2' + IDDOCMOD).val(ClearValue);

                jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Konto jest już potwierdzone - przejdź do logowania.</p>');
                jQuery('#Modal' + IDDOCMOD).modal('show');
              }
              else {
                jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Nie można uwieżytelnić konta lub nadać mu podanego hasła. Konto może już być uwierzytelnione. Spróbuj ponownie lub skontaktuj się z Administratorem.</p>');
                jQuery('#Modal' + IDDOCMOD).modal('show');
              }
            }
            else {
              jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Wystąpił błąd w komunikacji z serwerem.</p>');
              jQuery('#Modal' + IDDOCMOD).modal('show');
            }
          },
          //----------------------
          error: function (jqXHR, exception, errorThrown) {
            var Error = obj.BSAjaxError(jqXHR, exception, errorThrown);
            jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">' + Error + '</p>');
            jQuery('#Modal' + IDDOCMOD).modal('show');
          },
          //----------------------
          complete: function () { },
          dataType: 'json'
        });
      }
      else {
        jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Proszę wpisać hasło do konta. Identyczne hasło musi być wpisane w obu polach formularza.</p>');
        jQuery('#Modal' + IDDOCMOD).modal('show');
      }
    }
  }

  //---------------------------------------------
  // Analiza Daty i generowanie listy z unikalnymi godzinami
  //---------------------------------------------
  obj.Get3100Tools860AnalizeDateTimeGenerateList = function (DataObject) {
    var SERVICE = (typeof DataObject.SERVICE !== 'undefined') ? DataObject.SERVICE : null;
    var IDBAZA = (typeof DataObject.IDBAZA !== 'undefined') ? DataObject.IDBAZA : null;
    var IDCONFIG = (typeof DataObject.IDCONFIG !== 'undefined') ? DataObject.IDCONFIG : null;
    var IDPOLE = (typeof DataObject.IDPOLE !== 'undefined') ? DataObject.IDPOLE : null;
    var FIELDDATE = (typeof DataObject.FIELDDATE !== 'undefined') ? DataObject.FIELDDATE : null;
    var FIELDSELECT = (typeof DataObject.FIELDSELECT !== 'undefined') ? DataObject.FIELDSELECT : null;
    var CODE = (typeof DataObject.CODE !== 'undefined') ? DataObject.CODE : null;

    if (SERVICE == null || IDBAZA == null || IDCONFIG == null || IDPOLE == null || FIELDDATE == null || FIELDSELECT == null || CODE == null) {
      
      alert('Brak podstawowych parametrów.');

    }
    else {
      
      var CHECKDATE = jQuery('#' + FIELDDATE).val();
        
      jQuery.ajax({
        type: "POST",
        url: SERVICE,
        data: {
          IDSERVICE: 3100,
          IDMODULE: 3100,
          IDTOOLS: 860,
          IDRUN: 100,

          IDBAZA: IDBAZA,
          IDPOLE: IDPOLE,
          IDCONFIG: IDCONFIG,
          CHECKDATE: CHECKDATE,
          // FIELDDATE: FIELDDATE,
          // FIELDSELECT: FIELDSELECT,
          CODE: CODE
        },
        //----------------------
        beforeSend: function () {
          //jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Czekaj, wysyłam dane do logowania na serwer.</p>');
          //jQuery('#Modal' + IDDOCMOD).modal('show');
        },
        //----------------------
        success: function (data) {
          if ((typeof data.STATUS !== 'undefined') || (typeof data.ELEMENTS !== 'undefined')) {
            var STATUS = (typeof data.STATUS !== 'undefined') ? parseInt(data.STATUS) : 0;
            var ELEMENTS = (typeof data.ELEMENTS !== 'undefined') ? data.ELEMENTS : [];

            if (STATUS == 1) {
              var SelectList = jQuery("#"+FIELDSELECT);
              SelectList.empty(); // usuwamy wszystko
              
              if(ELEMENTS.length > 0)
              {
                jQuery.each(ELEMENTS, function(index, option) {
                  var o = jQuery("<option></option>").attr("value", option.value).text(option.label);
                  SelectList.append(o);
                });
              }
            }
            else {
              alert('Brak wykonanego skryptu analizy');
              //jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Podane dane do logowania są niepoprawne, konto nie istnieje, jest nieuwieżytelnione lub zablokowane.</p>');
              //jQuery('#Modal' + IDDOCMOD).modal('show');

            }
          }
          else {
            alert('Wystąpił błąd w komunikacji z serwerem.');
            //jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">Wystąpił błąd w komunikacji z serwerem.</p>');
            //jQuery('#Modal' + IDDOCMOD).modal('show');
          }
        },
        //----------------------
        error: function (jqXHR, exception, errorThrown) {
          var Error = obj.BSAjaxError(jqXHR, exception, errorThrown);
          
          alert(Error);
          
          //jQuery('#Modal' + IDDOCMOD + ' .modal-body').html('<p class="p-0 m-0">' + Error + '</p>');
          //jQuery('#Modal' + IDDOCMOD).modal('show');
        },
        //----------------------
        complete: function () { },
        dataType: 'json'
      });


    }
  }

  //---------------------------------------------
  // Obsługa błędów Ajax
  //---------------------------------------------
  obj.BSAjaxError = function (jqXHR, exception, errorThrown) {
    var Blad = '';
    if (jqXHR.status === 0) {
      Blad = 'Brak połączenia z serwerwm.';
    } else if (jqXHR.status == 404) {
      Blad = 'Wywoływana strona nie istniej. [404]';
    } else if (jqXHR.status == 500) {
      Blad = 'Wewnątrzny błąd serwera [500].';
    } else if (exception === 'parsererror') {
      Blad = 'Przesłany plik JSON jest wadliwy.';
    } else if (exception === 'timeout') {
      Blad = 'Za długi czas oczekiwania.';
    } else if (exception === 'abort') {
      Blad = 'Przerwano transmisję danych.';
    } else {
      Blad = 'Błąd ' + jqXHR.responseText;
    }

    return 'Wystąpił błąd: ' + Blad;
  }

  return obj;
}
